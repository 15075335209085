var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-typography "},[_c('h2',[_vm._v("My Fonts")]),_c('uploader',{staticClass:"uploader",attrs:{"uploaderType":"font","supportedFileTypes":_vm.supportedFileTypes},on:{"upload":function (file) {
        this$1.$_dashboardMixin_uploadFontToS3(file, _vm.supportedFileTypes).then(
          function () {
            this$1.getAllUserFonts();
          }
        );
      }}}),_c('loading',{attrs:{"active":_vm.$wait.is('loadingFonts') ||
        _vm.$wait.is('uploadingFont') ||
        _vm.$wait.is('deletingFont'),"is-full-page":false}}),_c('img',{staticClass:"img-bg-typography",attrs:{"src":(_vm.publicPath + "assets/bg-typography.svg"),"alt":""}}),(_vm.userUploadedFonts.length > 0)?_c('el-table',{ref:"multipleTable",staticClass:"mt4",staticStyle:{"width":"100%"},attrs:{"data":_vm.userUploadedFonts},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"label":"Font Family"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:({ fontFamily: scope.row.font_name })},[_vm._v(_vm._s(scope.row.font_name))])]}}],null,false,3863761945)}),_c('el-table-column',{attrs:{"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}},[_vm._v("Delete")])]}}],null,false,1425991935)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }