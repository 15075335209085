<template>
  <div class="page-typography ">
    <h2>My Fonts</h2>

    <uploader
      @upload="
        (file) => {
          this.$_dashboardMixin_uploadFontToS3(file, supportedFileTypes).then(
            () => {
              this.getAllUserFonts();
            }
          );
        }
      "
      uploaderType="font"
      class="uploader"
      :supportedFileTypes="supportedFileTypes"
    ></uploader>

    <loading
      :active="
        $wait.is('loadingFonts') ||
          $wait.is('uploadingFont') ||
          $wait.is('deletingFont')
      "
      :is-full-page="false"
    ></loading>

    <img
      class="img-bg-typography"
      :src="`${publicPath}assets/bg-typography.svg`"
      alt=""
    />

    <el-table
      v-if="userUploadedFonts.length > 0"
      ref="multipleTable"
      :data="userUploadedFonts"
      style="width: 100%"
      class="mt4"
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="Font Family">
        <template slot-scope="scope">
          <span :style="{ fontFamily: scope.row.font_name }">{{
            scope.row.font_name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import { loadAWSsdk } from "@/libs/aws-manager";
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";
import { deleteS3Obj } from "@/libs/aws-manager";
import uploader from "@/components/common/uploader/uploader.component";

export default {
  mixins: [DASHBOARD_MIXIN],
  components: {
    uploader,
  },
  data() {
    return {
      supportedFileTypes: ["otf", "ttf", "woff", "woff2"],
    };
  },
  computed: {
    ...mapGetters("authModule", ["userInfo"]),
    ...mapGetters("brandModule", ["userUploadedFonts"]),
    ...mapActions("brandModule", ["getUserUploadedFonts", "deleteFont"]),
  },
  mounted: function() {
    loadAWSsdk();

    this.getAllUserFonts();
  },
  methods: {
    getAllUserFonts() {
      this.$wait.start("loadingFonts");

      this.$store
        .dispatch("brandModule/getUserUploadedFonts")
        .then((res) => {
          this.$wait.end("loadingFonts");
        })
        .catch((e) => {
          this.$wait.end("loadingFonts");
        });
    },

    handleDelete(index, row) {
      this.$confirm(
        "Delete this font? This will also affect the projects you have used this font in. Continue?",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          this.$wait.start("deletingFont");

          this.$store
            .dispatch("brandModule/deleteFont", { id: row._id })
            .then((res) => {
              deleteS3Obj(row.key)
                .then((s3Res) => {
                  this.$wait.end("deletingFont");
                  this.getAllUserFonts();
                })
                .catch((e) => {
                  this.$wait.end("deletingFont");
                  console.warn(e);
                });
            })
            .catch((e) => {
              this.$wait.end("deletingFont");
              console.warn(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "typography.component.scss";
</style>
